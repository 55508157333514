<template>
  <BaseModal
    v-model="model"
    size="md"
    :loading="loading"
    :confirmButtonDisabled="disabledSave"
    @confirm-button-click="handleSaveButtonClick"
  >
    <h1 class="font-large-1 text-center pt-2">¿Que deseas hacer?</h1>
    <div class="p-2">
      <b-form-radio v-model="action" value="create" class="mb-1">
        Crear nuevo job
      </b-form-radio>
      <div v-if="action === 'create' && isMaintenance" class="pl-2">
        <validation-observer ref="createdJob">
          <validation-provider
            v-slot="validationContext"
            name="Duracion del contrato"
            rules="required"
          >
            <b-form-group
              label="Selecciona la duracion del contrato"
              label-for="contract-duration"
            >
              <BaseSelect
                id="contract-duration"
                http-method="get"
                :value="duration"
                :resource="
                  $data.$constants.RESOURCES.RESOURCE_CONTRACT_DURATION
                "
                @input="duration = $event"
              />
              <small class="text-danger">
                {{ validationContext.errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="validationContext"
            name="Generar Facturación por"
            rules="required"
          >
            <b-form-group
              label="Generar Facturación por:"
              label-for="prorate_certification"
            >
              <v-select
                id="prorate_certification"
                :value="prorate_certification"
                :options="[
                  {
                    name: 'Prorratear el coste de la oferta entre revisiones',
                    value: 1,
                  },
                  { name: 'Por valor de revisión', value: 0 },
                ]"
                :reduce="(item) => item.value"
                label="name"
                @input="prorate_certification = $event"
              />
              <small class="text-danger">
                {{ validationContext.errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </div>
      <b-form-radio v-model="action" value="overturn" class="mb-1">
        Volcar en adicionales
      </b-form-radio>
      <div v-if="action === 'overturn'" class="pl-2">
        <validation-observer ref="selectedJob">
          <validation-provider
            v-slot="validationContext"
            name="Job"
            rules="required"
          >
            <b-form-group label="Job" label-for="sale-invoice-job">
              <BaseSelect
                id="sale-invoice-job"
                :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
                :httpBody="{ status: jobStatusId }"
                :value="job"
                @input="job = $event"
              />
              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import OffersApi from "@/api/offers-api";
import statusApi, { JOBS_STATUS_TYPE } from "@/api/status-api";
import { mapGetters } from "vuex";

export default {
  name: "PassOfferToJobModal",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    offer: {
      type: Object,
    },
  },
  components: {
    BaseModal,
    BaseSelect,
  },
  data() {
    return {
      loading: false,
      action: "",
      duration: null,
      job: null,
      jobStatusId: "",
      prorate_certification: null,
    };
  },
  computed: {
    ...mapGetters("offer", {
      isMaintenance: "getIsMaintenance",
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    disabledSave() {
      return this.action.length === 0;
    },
  },
  methods: {
    async handleSaveButtonClick() {
      this.loading = true;

      try {
        let response;
        if (this.action === "create") {
          if (this.isMaintenance) {
            const valid = await this.$refs.createdJob.validate();
  
            if (!valid) {
              this.loading = false;
              return;
            }
          }

          let params = {};

          if (this.duration) {
            params.contract_duration = this.duration.id;
          }
          if (this.prorate_certification) {
            params.prorate_certification = this.prorate_certification;
          }

          response = await OffersApi.createJob(this.offer?.id, params);
        }
        if (this.action === "overturn") {
          const valid = await this.$refs.selectedJob.validate();
          if (!valid) {
            this.loading = false;
            return;
          }
          response = await OffersApi.createJobAditionals(this.offer?.id, {
            job_id: this.job.id,
          });
        }
        await this.$router.push({
          name: "viewJob",
          params: { id: response.data.data.id },
        });
        this.$toast("Job creado con éxito.");
      } catch (error) {
        this.$toast.error("Ocurrió un error al crear el job");
        this.$toast.error(error.response?.data?.error)
      }
      this.loading = false;
    },
  },
  async mounted() {
    const response = await statusApi.list(JOBS_STATUS_TYPE);
    this.jobStatusId = response.data.data.find(
      ({ name }) => name === "Abierto"
    );
  },
};
</script>
